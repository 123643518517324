import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { BiHome, BiRefresh } from "react-icons/bi"
import { Button } from "baseui/button"
import Box from "../../components/Box"
import Text from "../../components/Text"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import ErrorImage from "../../images/404.svg"
import { ErrorWrapper, ErrorConatent, ButtonWrapper } from "./error.style"

const ErrorSec = ({ imageWrapper, title, text }) => {
  const pageReload = () => {
    window.location.reload()
  }
  return (
    <ErrorWrapper>
      <ErrorConatent>
        <Box {...imageWrapper} className="image-wrapper">
          <Image src={ErrorImage} alt="404" />
        </Box>
        <Heading {...title} content="Page not found!" />
        <Text
          {...text}
          content="Looks like the page you're trying to visit does not exist. Please check the URL and try again."
        />
        <ButtonWrapper>
          <Button startEnhancer={<BiRefresh size={24} />} onClick={pageReload}>
            Reload Page
          </Button>
          <Link href="/">
            <Button startEnhancer={<BiHome size={24} />}>Go Home</Button>
          </Link>
        </ButtonWrapper>
      </ErrorConatent>
    </ErrorWrapper>
  )
}

ErrorSec.propTypes = {
  imageWrapper: PropTypes.object,
}

ErrorSec.defaultProps = {
  imageWrapper: {
    mb: ["40px", "55px"],
  },
  title: {
    fontSize: ["24px", "26px", "30px", "36px", "40px"],
    lineHeight: ["30px", "32px", "40px", "50px", "55px"],
    fontWeight: "700",
    color: "#32325d",
    letterSpacing: "-0.010em",
    textAlign: "center",
    mb: "20px",
    maxWidth: ["100%", "100%", "100%", "100%", "415px"],
  },
  text: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "28px",
    textAlign: "center",
    mb: ["30px", "30px", "35px", "35px", "45px"],
    maxWidth: ["100%", "100%", "100%", "100%", "430px"],
  },
}

export default ErrorSec

import React from "react"
import { GlobalStyle } from "../components/app.style"
import { SEOPage } from "../components/SEO"
import ErrorSec from "../containers/Error"

const browser = typeof window !== "undefined" && window

const NotFoundPage = () =>
  browser && (
    <>
      <SEOPage title="404 Page not found" />
      <GlobalStyle />
      <ErrorSec />
    </>
  )

export default NotFoundPage
